import React from "react";
import Navbar from "./Pages/Components/Navbar";
import Footer from "./Pages/Components/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Service from "./Pages/Service";
import Advantage from "./Pages/Advantage";
import Product from "./Pages/Product";

function App() {
  return (
    <>
      <Navbar />  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        <Route path="/products" element={<Product />} />
        <Route path="/advantages" element={<Advantage />} />
      </Routes> 
      <Footer/>

      {/* <Footer /> */}
    </>
  );
}

export default App;
