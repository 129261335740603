import React, { Fragment } from "react";
import Building from "../../Assets/Image/building.png";
import MapBg from "../../Assets/Image/9267.jpg";
import thumbnail from "../../Assets/Image/thumbnail.jpg";
import { Dialog, Transition } from "@headlessui/react";
import Content1 from "../../Assets/Content/Content1.jpeg";
import Content2 from "../../Assets/Content/Content2.jpeg";
import Content3 from "../../Assets/Content/Content3.jpeg";
import Content4 from "../../Assets/Content/Content4.jpeg";
import Content5 from "../../Assets/Content/Content5.jpeg";
import Content6 from "../../Assets/Content/Content6.jpeg";
import Content7 from "../../Assets/Content/Content7.jpeg";
import Content8 from "../../Assets/Content/Content8.jpeg";

export default function Advantage() {
  const list = [
    { id: 1, url: Content1, title: "Cabling & Maintenance" },
    { id: 2, url: Content2, title: "Data Center Facility & Service" },
    { id: 3, url: Content3, title: "Data Center Facility & Service" },
    { id: 4, url: Content4, title: "IT Consultant" },
    { id: 5, url: Content5, title: "Virtualization & Cloud Solution" },
    { id: 6, url: Content6, title: "App Development & Implement" },
    { id: 7, url: Content7, title: "Red Team & Blue Team" },
    { id: 8, url: Content8, title: "HW & IT intergration" },
  ];

  const photo = [1, 2, 3, 4, 5, 6];
  return (
    <>
      {/* Banner */}
      <div
        className="xl:py-48 lg:py-48 md:py-32 py-32 flex flex-col justify-center  w-full h-[70vh] bg-cover bg-center text-white xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 capitalize shadow-xl "
        style={{ backgroundImage: `url(${Building})` }}
      >
        <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
          <h1 className="xl:text-4xl lg:text-4xl md:text-2xl text-3xl font-thin">
            Our Company
            <span className="text-white font-semibold"> Documentation</span>
            <br />
            <span className="text-white font-thin">
              Get information about us
            </span>
          </h1>
        </div>
      </div>
      {/* Banner */}
      {/* List */}
      <div className="grid lg:grid-cols-2 grid-cols-1 justify-center py-20 lg:px-32 px-10 gap-10 w-full">
        {list.map((i, key) => (
          <Foto i={i} key={key} />
        ))}
      </div>
      {/* List */}
      {/* Map */}
      <div
        className=" lg:h-[70vh] h-[100vh]   w-full  flex flex-col items-center bg-top bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${MapBg})` }}
      >
        <div className="w-full bg-gray-500 px-20 py-10 h-full bg-opacity-20 flex flex-col items-center ">
          {/* title */}
          <div className="flex flex-col items-center text-black font-bold">
            <h1 className="text-4xl">Our Project By Numbers</h1>
            <p>Our Gallery / Company Project Documentation</p>
          </div>
          {/* title */}
          {/*  chart */}
          <div className="flex flex-col lg:flex-row justify-center items-center gap-20 m-auto text-black">
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-8xl">+25</h1>
              <p>Our Client & Parner</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-8xl">+572</h1>
              <p>Our Finished Project</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <h1 className="text-8xl">99%</h1>
              <p>Statisfy Consumen</p>
            </div>
          </div>
          {/*  chart */}
        </div>
      </div>
      {/* Map */}
      {/* photos */}
      <div className="lg:px-20 px-10 py-20 w-full">
        <div className="grid xl:grid-cols-4 mb-10 gap-4 mt-10">
          {photo.map((i, key) => (
            <div
              className={`w-full ${
                key === 0 && "col-span-2 row-span-2 max-h-[650px] min-h-[650px]"
              } bg-gray-300  w-full  min-h-[310px] bg-cover bg-center object-contain ${
                key === 3 && "col-span-2 row-span-2 max-h-[650px] min-h-[650px]"
              }`}
              key={key}
              style={{ backgroundImage: `url(${thumbnail})` }}
            ></div>
          ))}
        </div>
      </div>
      {/* photos */}
    </>
  );
}

function Foto({ key, i }) {
  const [open, setOpen] = React.useState(false);
  const cancelButtonRef = React.useRef(null);
  return (
    <>
      <div
        onClick={() => {
          setOpen(true);
        }}
        key={key}
        className="my-auto items-center relative"
      >
        <div
          className=" rounded-t-lg mx-auto max-h-96 min-w-full bg-no-repeat bg-cover "
          style={{ backgroundImage: `url(${i.url})` }}
        >
          <div
            className={` rounded-t-lg  xl:p-28 md:p-20 sm:p-36 p-20  bg-black bg-opacity-25 transition ease-in-out `}
          >
            <div className="mx-auto flex justify-center items-center "></div>
            <div class=" absolute justify-center flex flex-col bottom-0 left-0 right-0 bg-black bg-opacity-25">
              <p class="text-center uppercase font-bold text-white xl:text-base py-3 lg:text-base md:text-sm text-sm truncate">
                {i.title}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        data={i}
        cancelButtonRef={cancelButtonRef}
        setOpen={setOpen}
      />
    </>
  );
}

function Modal({ open, data, setOpen, cancelButtonRef }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>
        </Transition.Child>
        <div className="fixed z-20 inset-0 overflow-y-auto">
          <div className="flex items-end md:pt-32 md:pb-28 md:my-0 py-32 justify-center min-h-full p-4 text-center ">
            <div className="cursor-pointer flex absolute xl:right-[19.5rem] lg:right-10 right-5 2xl:top-16 top-10 text-white">
              <svg
                onClick={() => {
                  setOpen(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel>
                <div className=" items-center flex flex-col justify-center">
                  <img
                    className=" rounded-lg  2xl:min-w-[680px] 2xl:min-h-[443px] 2xl:max-h-[443px] md:min-w-[490px] md:min-h-[318px] md:max-h-[318px] min-w-[353px] min-h-[215px] max-h-[215px]"
                    src={data.url}
                    alt=""
                  />
                  <div className=" items-center gap-y-5 flex flex-col mt-10 xl:w-1/2 lg:w-3/4 md:w-1/2  w-11/12">
                    <h1 className="font-semibold text-white lg:text-lg">
                      {data.title}
                    </h1>

                    {/* <p className="text-white lg:w-3/4 md:w-full sm:w-1/2 w-4/5  2xl:text-sm text-xs font-extralight">
                    {summary}
                  </p> */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
