import React from "react";
import Bg from "../../Assets/Image/earth-night.jpeg";
import Logo from "../../Assets/Logo/logowhite.svg";
import AOS from "aos";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "aos/dist/aos.css";
import "swiper/css";
import { useNavigate, Link } from "react-router-dom";
import Building from "../../Assets/Image/building.png";
import Peris from "../../Assets/Logo/Peris.ai.png";
import Sandhi from "../../Assets/Logo/Sandhiguna.png";
import Hat from "../../Assets/Logo/Red Hat.png";
import Giga from "../../Assets/Logo/Gigamon.png";
import Nuta from "../../Assets/Logo/Nutanix.png";
import LKPP from "../../Assets/Logo/LKPP.png";
import LPSE from "../../Assets/Logo/LPSE.png";
import Catalogue from "../../Assets/Logo/Catalogue.png";
import Code from "../../Assets/Image/code.png";
import Consultant from "../../Assets/Image/Laptop-preview.png"
import SecurityCctv from "../../Assets/Image/CCTV-preview.png"
import Hardware from "../../Assets/Image/Hardware-preview.png"
import ITManagement from "../../Assets/Image/IT-preview.png"
import { MonitorMobbile } from "iconsax-react";
import { People } from "iconsax-react";
import { FolderOpen } from "iconsax-react";
import emailjs from "@emailjs/browser";
import BgVideo from "../../Assets/Videos/bg_banner.mp4";
export default function Home() {
  const navigate = useNavigate();
  const swiperRef = React.useRef();
  const carou = [
    {
      id: 1,
      img: Peris,
    },
    {
      id: 3,
      img: Hat,
    },
    {
      id: 4,
      img: Giga,
    },
    {
      id: 5,
      img: Nuta,
    },
  ];

  const services = [
    {
      title: "IT CONSULTANT",
      imgser: Consultant ,
    },
    {
      title: "IT SECURITY",
      imgser: SecurityCctv,
    },
    {
      title: "Manage Service Security Operating center",
      imgser: ITManagement,
    },
    {
      title: "Hardware & IT Intergration",
      imgser: Hardware,
    },
  ];
  // advantage
  const advan = [
    {
      id: 1,
      IconB: <MonitorMobbile color="#2392D0" variant="Bold" size={54} />,
      desc: "IT consultant team that provides and supports you in delivering quick solutions to a problem.",
    },
    {
      id: 2,
      IconB: <People color="#2392D0" variant="Bold" size={54} />,
      desc: "Having team of professionals with excellent expertise in getting the job well done.",
    },
    {
      id: 3,
      IconB: <FolderOpen color="#2392D0" variant="Bold" size={54} />,
      desc: "Provide the ability and expertise to produce the new works.We can produce flexible solutions and always produce better solutions.",
    },
  ];

  React.useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      {/* Banner */}
      <div className="lg:px-8 px-5 py-8 ">
        <div
          className="relative flex flex-col justify-center  w-full h-[82vh] bg-cover bg-center text-white rounded-[25px] capitalize  shadow-xl"
          style={{ backgroundImage: `url(${Bg})` }}
        >
          <video
            className="absolute top-0 left-0 h-full w-full object-cover rounded-[25px]"
            autoPlay
            loop
            muted
          >
            <source src={BgVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-10 w-full h-full xl:py-48 lg:py-48 md:py-32 py-32 xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 rounded-[25px] bg-black bg-opacity-60">
            <div className="absolute bottom-5 right-10 items-center md:flex hidden gap-x-5 bg-white rounded-xl p-4">
              <img className="h-8" src={LPSE} alt="" />
              <img className="h-8" src={LKPP} alt="" />
              <img className="h-8 mr-2" src={Catalogue} alt="" />
            </div>

            <div className="flex lg:flex-row flex-col-reverse lg:items-center justify-between w-full">
              <div className="left w-full">
                <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
                  <h1 className="xl:text-5xl lg:text-5xl md:text-2xl text-xl font-thin">
                    Welcome to the{" "}
                    <span className="text-white font-semibold">World</span>{" "}
                    <br /> of{" "}
                    <span className="text-white font-semibold">Tommorow</span>
                  </h1>
                  <p className="mt-7 mb-3 lg:text-lg">
                    <span className="font-bold">
                      We providing integrated strategic solutions that are
                      suitable for every business organization by creating
                      better business processes. Providing the BEST information
                      technology solutions to support our client’s business.
                    </span>
                  </p>
                </div>
                {/* <div className="flex md:flex-row flex-col w-full lg:text-base text-sm justify-left lg:gap-x-12 items-center">
                  <button
                    onClick={() => navigate("/about")}
                    className="bg-[#517698] hover:bg-blue-500 capitalize font-semibold flex items-center text-white px-10 py-1 rounded-full outline outline-offset-1 outline-1 lg:mt-10 mt-5"
                  >
                    See More For Details
                    <svg
                      className="ml-10 -mr-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill="#FFFFFF"
                        d="M15.2 10.492l-1.97-1.97-3.21-3.21c-.68-.67-1.84-.19-1.84.77v11.84c0 .96 1.16 1.44 1.84.76l5.18-5.18c.83-.82.83-2.18 0-3.01z"
                      ></path>
                    </svg>
                  </button>
                  <button
                    onClick={() => navigate("/advantages")}
                    className="bg-[#517698] hover:bg-blue-500 capitalize font-semibold flex items-center text-white px-10 py-1 rounded-full outline outline-offset-1 outline-1 lg:mt-10 mt-5"
                  >
                    See Company Project
                    <svg
                      className="ml-10 -mr-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      strokeWidth={1.5}
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill="#FFFFFF"
                        d="M15.2 10.492l-1.97-1.97-3.21-3.21c-.68-.67-1.84-.19-1.84.77v11.84c0 .96 1.16 1.44 1.84.76l5.18-5.18c.83-.82.83-2.18 0-3.01z"
                      ></path>
                    </svg>
                  </button>
                </div> */}
              </div>
              <div className="right lg:w-1/3">
                <img className="" src={Logo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Banner */}
      {/* Products */}
      <div className="lg:px-8 px-5 py-6 " data-aos="fade-up">
        <div
          className="relative flex flex-col justify-center  w-full h-[95vh] bg-cover bg-center text-white rounded-[25px] capitalize  shadow-xl"
          style={{ backgroundImage: `url(${Building})` }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full xl:py-28 lg:py-14 md:py-10 py-10 rounded-[25px] bg-black bg-opacity-20">
            <div className="title flex text-white flex-col gap-y-3 justify-center items-center">
              <h1 className="font-bold lg:text-4xl text-3xl">Our Product</h1>
              <p className="lg:text-2xl text-xl">
                {" "}
                Our Product / Viktorindo Teknologi Prima
              </p>
            </div>
            {/* <div className="grid grid-cols-3 gap-x-10 pt-10">
              {carou.map((i, key) => (
                <div className="rounded-2xl bg-white px-8 py-28">
                  <img src={i.img} alt="" className="object-contain h-48 w-96" />
                </div>
              ))}
            </div> */}
            <Swiper
              className="w-full mySwiper mt-20"
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              pagination={{
                el: ".swiper-scrollbar",
                draggable: true,
                hide: false,
              }}
              // breakpoints={{
              //   640: {
              //     slidesPerView: 2,
              //   },
              //   768: {
              //     slidesPerView: 1,
              //   },
              //   1024: {
              //     slidesPerView: 2,
              //   },
              //   1560: {
              //     slidesPerView: 3,
              //   },
              // }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10, // Adjust this value for the desired spacing on smaller screens
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20, // Adjust this value to add space between slides on smaller screens
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 3,
                slideShadows: false,
              }}
              slidesOffsetBefore={60}
            >
              {carou.slice(0, 6).map((i, key) => (
                <SwiperSlide>
                  <div className="rounded-2xl bg-white  lg:px-8 lg:py-28 px-5 py-20 h-full flex justify-center items-center">
                    <img
                      src={i.img}
                      alt=""
                      className="object-contain min-w-[100px]  max-w-[300px]"
                    />
                  </div>
                </SwiperSlide>
              ))}

              {carou.length > 6 && (
                <SwiperSlide>
                  <div className="rounded-2xl bg-white px-8 py-18 h-full flex justify-center items-center">
                    <button className="relative flex justify-center items-center gap-x-1 font-bold group text-blue-600">
                      See More
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="h-0 transition-all duration-300 group-hover:h-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m8.25 4.5 7.5 7.5-7.5 7.5"
                        />
                      </svg>
                      <div className="absolute bottom-0 left-0 w-0 h-0.5 bg-blue-600 transition-all duration-300 group-hover:w-full" />
                    </button>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
      </div>
      {/* Products */}
      {/* About Content */}
      <div className="px-8 py-20">
        <div className="flex lg:flex-row flex-col justify-between w-full h-full gap-x-36">
          <div className="left lg:w-1/2" data-aos="fade-right">
            <div
              className="w-full h-[60vh] rounded-2xl"
              style={{ backgroundImage: `url(${Code})` }}
            >
              <div className="w-full h-full flex justify-center items-center px-20  bg-white bg-opacity-30 rounded-2xl">
                <img className="filter brightness-0" src={Logo} alt="" />
              </div>
            </div>
          </div>
          <div className="right lg:w-3/4 lg:mt-0 mt-10 flex flex-col justify-between gap-y-5 text-[#2173AA]">
            <div className="flex flex-col gap-y-5">
              <div className="title gap-y-3 flex flex-col">
                <h1 className="font-bold text-4xl">
                  IT Cyber Security Company
                </h1>
                <h3 className="text-2xl">
                  About Our Company / Viktorindo Teknologi Prima
                </h3>
              </div>
              <p className="text-xl ">
                We are an IT Solutions Company that is expert and experienced in
                providing complete information technology solutions. This can
                help customers in various forms, from creating consulting,
                creating enterprise architecture to delivering excellence for
                customer service. We can assist in migration planning, change
                management and client business development.Our company is
                supported by competent, experienced and professional human
                resources as well as world-class partnerships with principals.
                We provide integrated strategic solutions that are suitable for
                every business organization by creating better business
                processes.
              </p>
            </div>
            <div className="px-10 py-3 lg:mt-0 mt-10 font-bold text-white text-xl cursor-pointer bg-[#1C406D] w-fit rounded-full">
              <Link to={"/about"}>See more</Link>
            </div>
          </div>
        </div>
      </div>
      {/*Content */}
      {/* Services */}
      <div className="lg:px-8 px-5 py-20">
        <div
          className="relative flex flex-col justify-center  w-full lg:h-[90vh] h-full bg-cover bg-center text-white rounded-[25px] capitalize  shadow-xl"
          style={{ backgroundImage: `url(${Building})` }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full xl:py-48 lg:py-48 md:py-32 py-32 xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 rounded-[25px] bg-white bg-opacity-20">
            <div className="title flex text-[#1C406D] flex-col gap-y-3 justify-center items-center">
              <h1 className="font-bold text-4xl">Our Services</h1>
              <p className="text-2xl">
                About Our Service / Viktorindo Teknologi Prima
              </p>
            </div>
            <div className="grid lg:grid-cols-4 gap-y-10 grid-cols-1 gap-x-20 pt-10 ">
              {services.map((i, key) => (
                <div
                  className="rounded-2xl bg-[#1C406D] px-5 py-10 h-[48vh] shadow-xl  "
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h1 className="font-bold text-xl text-white text-center">
                    {i.title}
                  </h1>
                  <div className="border-b-2 pt-2 "></div>
                  <img className="h-full" src={i.imgser} alt="" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Services */}
      {/* Advantage */}
      <div className="lg:px-8 px-5 py-20">
        <div className="flex flex-col gap-y-20">
          <div className="title flex text-[#1C406D] flex-col gap-y-3 justify-center items-center">
            <h1 className="font-bold text-4xl">Our Advantage</h1>
            <p className="text-2xl">
              Special Offer / Viktorindo Teknologi Prima
            </p>
          </div>
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-y-10 gap-x-20 pt-10 px-20">
            {advan.map((i, key) => (
              <div className="rounded-2xl bg-[#C4C4C480] px-8 py-10 flex flex-col items-center gap-y-10 shadow-xl">
                <div className="top p-10 bg-[#1C406D] rounded-full w-fit ">
                  {i.IconB}
                </div>
                <p className="text-center">{i.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Advantage */}
      {/*Contact Us*/}
      <div className="lg:px-8 px-5 py-20">
        <div
          className="relative flex flex-col justify-center  w-full lg:h-[90vh] h-full  bg-cover bg-center text-white rounded-[25px] capitalize  shadow-xl"
          style={{ backgroundImage: `url(${Building})` }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full lg:py-0 py-20 xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 rounded-[25px] bg-black bg-opacity-20">
            <div className="title flex text-[#ffff] flex-col gap-y-3 justify-center lg:items-center mb-10">
              <h1 className="font-bold text-6xl">Contact Us</h1>
              <p className="text-xl">
                We are the best enterprise IT Service Partner to support your
                business
              </p>
            </div>
            <div className="flex lg:flex-row flex-col justify-between item-center gap-x-10 pt-10">
              <div className="lg:w-1/2 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
                <iframe
                  width="100%"
                  height="100%"
                  className="absolute inset-0"
                  frameborder="0"
                  title="map"
                  marginheight="0"
                  marginwidth="0"
                  scrolling="no"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8672938864183!2d106.91603687509482!3d-6.148519193838531!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698b9218a79b67%3A0xf398bbaac4b857c2!2sVIKTORINDO%20TEKNOLOGI%20PRIMA!5e0!3m2!1sid!2sid!4v1701922294772!5m2!1sid!2sid"
                ></iframe>
                <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                  <div className="lg:w-1/2 px-6">
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                      ADDRESS
                    </h2>
                    <p className="mt-1 text-gray-900">
                      Jl. Grand Orchard No.D10, RT.9/RW.1, Sukapura, Kec.
                      Cilincing, Jkt Utara, Daerah Khusus Ibukota Jakarta 14140
                    </p>
                  </div>
                  <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                      EMAIL
                    </h2>
                    <a href="email" className="text-gray-900 leading-relaxed lowercase">
                      support@viktorindo.co.id
                    </a>
                    <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                      PHONE
                    </h2>
                    <p className="leading-relaxed text-gray-900">(+62)821-2251-2660</p>
                  </div>
                </div>
              </div>
              <Contact />
            </div>
          </div>
        </div>
      </div>
      {/*Contact Us*/}
    </>
  );
}

function Contact() {
  const [toSend, setToSend] = React.useState({
    user_name: "",
    user_email: "",
    message: "",
  });
  const form = React.useRef();
  const [load, setLoad] = React.useState(false);
  const onSubmit = (e) => {
    setLoad(true);
    e.preventDefault();
    if (
      toSend.user_name == "" ||
      toSend.user_email == "" ||
      toSend.message == ""
    ) {
      setLoad(false);
      return alert("Mohon masukkan form anda dengan benar");
    } else {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAIL_KEY,
          process.env.REACT_APP_TEMPLATE_KEY,
          form.current,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          () => {
            console.log("HAI");
          },
          (error) => {
            console.log(error);
          }
        );
      setLoad(false);

      e.target.reset();
      setToSend({ user_email: "", user_name: "", message: "" });
    }
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
    console.log(toSend);
  };
  return (
    <form
      ref={form}
      onSubmit={onSubmit}
      className="lg:w-1/3 md:w-1/2  flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
    >
      <h2 className="text-white text-xl  font-medium title-font">Feedback</h2>
      <p className="leading-relaxed mb-10 text-lg text-white">
        Question, Reports, Feedback - Try to Contact Us here
      </p>
      <div className="relative mb-4 ">
        {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
        <input
          type="text"
          id="name"
          name="user_name"
          onChange={handleChange}
          value={toSend.user_name}
          placeholder="Name"
          className="w-full bg-white text-black rounded-xl border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none placeholder-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        {/* <label for="email" className="leading-7 text-sm text-gray-600">Email</label> */}
        <input
          type="email"
          id="email"
          onChange={handleChange}
          value={toSend.user_email}
          name="user_email"
          placeholder="Email"
          className="w-full bg-white text-black rounded-xl border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none placeholder-black py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
        />
      </div>
      <div className="relative mb-4">
        {/* <label for="message" className="leading-7 text-sm text-gray-600">Message</label> */}
        <textarea
          id="message"
          name="message"
          onChange={handleChange}
          value={toSend.message}
          placeholder="Message..."
          className="w-full bg-white text-black rounded-xl border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none placeholder-black py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
        ></textarea>
      </div>
      <button
        disabled={load ? true : false}
        className="text-black bg-white border-0 py-2 px-6 focus:outline-none rounded text-lg"
      >
        {load ? "Loading..." : "Submit"}
      </button>
    </form>
  );
}