import React from "react";
import Building from "../../Assets/Image/building.png";
import Customer from "../../Assets/Image/OurCustomers.png";
import Partner from "../../Assets/Image/OurPartners.png";
import Visi from "../../Assets/Image/Visi.png";
import Misi from "../../Assets/Image/Misi.png";
import ITCompany from "../../Assets/Image/IT company.jpg"

export default function About() {
  return (
    <>
      {/* Banner */}
      <div
        className="xl:py-48 lg:py-48 md:py-32 py-32 flex flex-col justify-center  w-full h-[70vh] bg-cover bg-center text-white xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 capitalize shadow-xl "
        style={{ backgroundImage: `url(${Building})` }}
      >
        <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
          <h1 className="xl:text-4xl lg:text-4xl md:text-2xl text-3xl font-thin">
            All About Our Company <br />
            <span className="text-white font-semibold">Viktorindo</span>
            <span className="text-white font-thin">Teknologi Prima</span>
          </h1>
          <p className="mt-7 mb-3 text-m">
            <span className="font-semibold">Having team of professionals</span>{" "}
            with excellent expertise <br /> in getting the job well done
          </p>
        </div>
      </div>
      {/* Banner */}
      {/* IT Solution */}
      <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
          <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal mb-8 text-gray-900">
            IT Solution Company
          </h1>
          <p className="mb-8  leading-relaxed">
            We are an IT Solutions Company that is expert and experienced in
            providing complete information technology solutions. This can help
            customers in various forms, from creating consulting, creating
            enterprise architecture to delivering excellence for customer
            service. We can assist in migration planning, change management and
            client business development.
          </p>
          <p>
            Our company is supported by competent, experienced and professional
            human resources as well as world-className partnerships with
            principals. We provide integrated strategic solutions that are
            suitable for every business organization by creating better business
            processes.
          </p>
        </div>

        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img
            className="object-cover object-center rounded "
            alt="hero"
            src={ITCompany}
          />
        </div>
      </div>
      {/* IT Solution */}
      {/* Visi & Misi */}
      <div className="container px-5 py-24 mx-auto bg-[#2392D0] shadow-xl ">
        <div className="text-center mb-20">
          <h1 className="sm:text-6xl text-2xl font-thin title-font text-white mb-4">
            Vision and Mission <br /> Viktorindo Teknologi Prima
          </h1>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6 ">
          <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div className="w-40 h-40 inline-flex items-center justify-center text-indigo-500 mb-5 flex-shrink-0">
              <img
                className="lg:w-3/4 md:w-3/6 w-5/6 mb-5 object-cover object-center rounded"
                alt="hero"
                src={Visi}
              />
            </div>
            <div className="flex-grow">
              <h2 className="text-white text-lg title-font font-medium mb-3">
                Our Company Vision
              </h2>
              <p className="leading-relaxed text-base text-white">
                “To be one of the Best Information Technology Provider with the
                Most Excellent Service”
              </p>
            </div>
          </div>
          <div className="p-4 md:w-1/2 flex flex-col text-center items-center">
            <div className="w-40 h-40 inline-flex items-center justify-center  mb-5 flex-shrink-0">
              <img
                className="lg:w-3/4 md:w-3/6 w-5/6 mb-5 object-cover object-center rounded"
                alt="hero"
                src={Misi}
              />
            </div>
            <div className="flex-grow">
              <h2 className="text-white text-lg title-font font-medium mb-3">
                Our Company Mission
              </h2>
              <p className="leading-relaxed text-base text-white list-disc">
                <li>
                  Provide our Customer with the best and mostefficient IT
                  Solution from our world class Principles
                </li>
                <li>
                  Develop the best strategy from planning to implementation of
                  your future IT Solution
                </li>
                <li>
                  Create excellent business process 
                </li>
                <li>Give the best professional
                  services with the right resources to support our Customer</li>
                <li>Maintaining the highest level of customer satisfaction</li>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Visi & Misi */}
      {/* Our Partner */}
      <div className="container mx-auto flex px-5 py-20    items-center justify-center flex-col">
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-[#2173AA] font-normal">
            Our Partners
          </h1>
          <h2 className="title-font sm:text-2xl text-xl mb-8 font-medium text-gray-900 font-normal">
            Most Trusted and Value Added Distributors in Indonesia
          </h2>
        </div>
        <img
          className="lg:w-3/4 md:w-3/6 w-5/6 mb-5 object-cover object-center rounded"
          alt="hero"
          src={Partner}
        />
      </div>
      {/* Our Partner */}

      {/* Our Customer */}
      <div className="container mx-auto flex px-5 py-16 items-center justify-center flex-col">
        <div className="text-center lg:w-2/3 w-full">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-[#2173AA] font-normal">
            Our Customers
          </h1>
          <h2 className="title-font sm:text-2xl text-xl mb-8 font-medium text-gray-900 font-normal">
            Most Treasured Clients
          </h2>
        </div>
        <img
          className="lg:w-3/4 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
          alt="hero"
          src={Customer}
        />
      </div>
      {/* Our Customer */}
    </>
  );
}
