import React from "react";
import Logo from "../../Assets/Logo/logowhite.svg";
import Logo2 from "../../Assets/Logo/Peris.ai.png";

export default function Footer() {
  return (
    <>
      <div className="w-full relative -mb-5 rounded-b-2xl h-[20px]"></div>
      <footer className="flex xl:flex-row lg:flex-row flex-col justify-between w-full items-center xl:px-16 lg:px-16 md:px-10 sm:px-10 px-10 pt-10 text-base bg-[#071B34]">
        <div className="lg:w-1/4 w-full  lg:justify-start justify-center">
          <div className="flex flex-col w-1/2 justify-center  xl:my-0 lg:my-0 my-5  lg:items-center items-start">
            <img className="2xl:h-32 lg:h-20 h-20" src={Logo} alt={"logo"} />
          </div>
        </div>
        <div className=" flex flex-col text-left lg:w-3/4 w-full mt-10  mb-10">
          <p className="mb-5 font-bold text-white">
            We are the best enterprise IT Service Partner to support your
            business. Providing BEST Information Technology Solutions to support
            our client’s business
          </p>
          <div className="flex lg:flex-row flex-col lg:justify-start justify-center items-center   gap-10">
            <div className="flex flex-col">
              <p className="text-white font-semibold">
                Roxy Square, Jl. Kyai Tapa 1 Lantai LG Blok B9 No 16 - Jakarta
              </p>
              <p className="text-white font-semibold">
                Email : support@viktorindo.co.id
              </p>
            </div>
            <div className=" px-5 flex justify-start items-center rounded-b-lg ">
              <a
                href="/"
                className="text-[#071B34] rounded-full bg-white p-2"
              >
                <svg
                  fill="currentColor"
                  strokeWidth="2"
                  className="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/pt.-viktorindo-teknologi-prima/"
                className="ml-3 text-[#071B34] rounded-full bg-white p-2"
              >
                <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
            <circle cx="4" cy="4" r="2" stroke="none"></circle>
          </svg>
              </a>
              <a
                href="https://www.instagram.com/viktorindoteknologiprima/"
                className="ml-3 text-[#071B34] rounded-full bg-white p-2"
              >
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinejoin="round"
                  className="w-5 h-5 font-bold"
                  viewBox="0 0 24 24"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div className="bg-[#071B34] ">
        <div class="flex justify-center">
          <div class="w-[95%] h-0.5 rounded-full bg-white inline-flex"></div>
        </div>
        <div className="mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row justify-center items-center">
          <p className="text-white text-sm text-center sm:text-left">
            Copyright © 2023 Viktorindo Teknologi Prima
          </p>
        </div>
      </div>
    </>
  );
}
