import React from "react";
import Building from "../../Assets/Image/building.png";
import Employee1 from "../../Assets/Image/Employee1.png";
import Employee2 from "../../Assets/Image/Employee2.png";
import Teknisi1 from "../../Assets/Image/Teknisi6.jpg"
// import Teknisi2 from "../../Assets/Image/Teknisi.jpg"

export default function Service() {
  const [page, setPage] = React.useState(0);
  return (
    <>
  {page == 0 ? (
  <>
    {/* Banner */}
    <div
      className="xl:py-48 lg:py-48 md:py-32 py-32 flex flex-col justify-center  w-full h-[70vh] bg-cover bg-center text-white xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 capitalize shadow-xl "
      style={{ backgroundImage: `url(${Building})` }}
    >
      <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
        <h1 className="xl:text-4xl lg:text-4xl md:text-2xl text-3xl font-thin">
          Easily, Anywhere,
          <span className="text-white font-semibold">Anytime</span>
          <br />
          <span className="text-white font-thin">
            Get Information about us
          </span>
        </h1>
        <p className="mt-7 mb-3 text-m">
          <span className="font-semibold">
            We have professionals that has expertise and experince
          </span>
          <br /> in the IT field to meet client goals
        </p>
      </div>
    </div>
    {/* Banner */}
    {/* IT Solution */}
    <div className=" mx-auto flex px-5 py-24 md:flex-row flex-col items-center gap-5  justify-between w-full">
      <div className="md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal mb-8 text-gray-900">
          IT Solution Company
        </h1>
        <p className="mb-8 leading-relaxed">
          Information technology (IT) consulting services helo improve the
          performance, scalability and competitiveness of your company
          through right technology enablement and usage.
        </p>
        <p>Our IT Consultation includes:</p>
        <ul className="list-disc pl-5 flex flex-col justify-start items-start">
          <li>IT Strategy</li>
          <li>Planning</li>
          <li>Operation Efficiency</li>
          <li>Performance</li>
          <li>Improvementetc</li>
        </ul>
      </div>

      <div className="lg:max-w-lg lg:w-w-1/3 md:w-1/2 w-5/6 mb-10 md:mb-0">
        <img
          className="object-cover object-center rounded-md"
          alt="hero"
          src={Teknisi1}
        />
      </div>
    </div>
    {/* IT Solution */}
    {/* Virtualization */}
    <div className="lg:h-[40rem]  h-[70rem] bg-[#2392D0] flex justify-center items-center text-white">
      <div
        className="h-full lg:px-0 bg-left-bottom bg-no-repeat lg:pt-0 pt-10 px-5 w-full flex lg:items-center items-start  lg:justify-center"
        style={{
          backgroundImage: `url(${Employee2})`,
        }}
      >
        <div className="flex ">
          <div className="max-w-[1360px] flex flex-col w-full items-end justify-end">
            <div className="md:w-3/4 w-full lg:pl-24 md:pl-16 flex flex-col justify-start md:text-left items-start text-center text-white">
              <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-white">
                Virtualization and Cloud Solution
              </h1>
              <div className="flex mb-4"></div>
              <p className="leading-relaxed text-white mb-8 text-lg ">
                Change your traditional IT Environment to the more
                effective and efficient forms, Support and workload, any
                environment, and any application type.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Virtualization */}
    {/* DDI Solutions */}
    <div className=" mx-auto flex px-5 py-24 md:flex-row flex-col  items-center justify-between w-full">
      <div className="md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-gray-900">
          DDI Solutions
        </h1>
        <p className="mb-8 leading-relaxed">
          DDI has become a key networking technology for every IT
          organization. It is now more prominent than ever due to
          multi-cloud and multi-device prolferation, and also because of
          ever- evolving security threats thal conventional network
          security Offerings find hard to combat. An integrated DDI
          solution helps simplify and automate.
        </p>
      </div>

      <div className="lg:max-w-lg lg:w-w-1/3 md:w-1/2 w-5/6 mb-10 md:mb-0">
        <img
          className="object-cover object-center rounded"
          alt="hero"
          src={Employee1}
        />
      </div>
    </div>
    {/* DDI Solutions */}
    {/* Lan */}
    <div className="lg:h-[40rem]  h-[70rem] bg-[#2392D0] flex justify-center items-center text-white">
      <div
        className="h-full lg:px-0 bg-left-bottom bg-no-repeat lg:pt-0 pt-10 px-5 w-full flex lg:items-center items-start  lg:justify-center"
        // style={{
        //   backgroundImage: `url(${Employee2})`,
        // }}
      >
        <div className="flex ">
          <div className="max-w-[1360px] flex flex-col w-full items-end justify-end">
            <div className="md:w-3/4 w-full lg:pl-24 md:pl-16 flex flex-col justify-start md:text-left items-start text-center text-white">
              <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-white">
                LAN/WAN Management
              </h1>
              <div className="flex mb-4"></div>
              <p className="leading-relaxed text-white mb-8 text-lg ">
                Our technology is redefining cennectivity around the
                globe. We innovato across wireless and wired technology to
                meet industry-specific needs and the most demanding use
                cases. Our high performance network Infrastructure
                provides secure, reliable access to applications and
                services no matter how tough the environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Lan */}
    {/* Next */}
    <div className="py-20 w-full flex justify-center items-center">
      <button
        onClick={() => {
          setPage(1);
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Add smooth scrolling behavior
          });
        }}
        className="flex px-5 py-3 gap-x-2 bg-[#2173AA] text-white rounded-xl font-bold"
      >
        Next{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </div>
    {/* Next */}
  </>
) : (
  <>
    {/* Banner */}
    <div
      className="xl:py-48 lg:py-48 md:py-32 py-32 flex flex-col justify-center  w-full h-[70vh] bg-cover bg-center text-white xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 capitalize shadow-xl "
      style={{ backgroundImage: `url(${Building})` }}
    >
      <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
        <h1 className="xl:text-4xl lg:text-4xl md:text-2xl text-3xl font-thin">
          Easily, Anywhere,
          <span className="text-white font-semibold">Anytime</span>
          <br />
          <span className="text-white font-thin">
            Get Information about us
          </span>
        </h1>
        <p className="mt-7 mb-3 text-m">
          <span className="font-semibold">
            We have professionals that has expertise and experince
          </span>
          <br /> in the IT field to meet client goals
        </p>
      </div>
    </div>
    {/* Banner */}
    {/* App System & IT Implementation */}
    <div className=" mx-auto flex px-5 py-24 md:flex-row flex-col  items-center justify-between w-full">
      <div className="md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-gray-900">
          App System & IT Implementation
        </h1>
        <p className="mb-8 leading-relaxed">
          Provide innovatice system applications by meeting client
          expectations,in helping to plan, develop, test and deliver
          innovative software more quickly. Providing services in the eld
          of IT Systems such as applications used to support all
          activities in your company/business.
        </p>
      </div>

      <div className="lg:max-w-lg lg:w-w-1/3 md:w-1/2 w-5/6 mb-10 md:mb-0">
        {/* <img
          className="object-cover object-center rounded"
          alt="hero"
          src={Employee1}
        /> */}
      </div>
    </div>
    {/* App System & IT Implementation */}
    {/* Vulnerability Assessment */}
    <div className="lg:h-[40rem]  h-[70rem] bg-[#2392D0] flex justify-center items-center text-white">
      <div
        // className="h-full lg:px-0 bg-left-bottom bg-no-repeat lg:pt-0 pt-10 px-5 w-full flex lg:items-center items-start  lg:justify-center"
        // style={{
        //   backgroundImage: `url(${Employee2})`,
        // }}
      >
        <div className="flex ">
          <div className="max-w-[1360px] flex flex-col w-full items-end justify-end">
            <div className="md:w-3/4 w-full lg:pl-24 md:pl-16 flex flex-col justify-start md:text-left items-start text-center text-white">
              <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-white">
                Vulnerability Assessment
              </h1>
              <div className="flex mb-4"></div>
              <p className="leading-relaxed text-white mb-8 text-lg ">
                Systematic review of security weaknesses in an information
                system. It evaluates if the system is susceptible to any
                known vulnerabilities, assigns severity levels to those
                vulnerabilities, and recommends remediation or mitigation,
                if and whenever needed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Vulnerability Assessment */}
    {/* IT Operations Management */}
    <div className=" mx-auto flex px-5 py-24 md:flex-row flex-col  items-center justify-between w-full">
      <div className="md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
        <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-gray-900">
          IT Operations Management
        </h1>
        <p className="mb-8 leading-relaxed">
          Manage IT services and the supporting IT infrastructure. IT
          operations management includes IT operations control and
          facilities management.
        </p>
      </div>

      <div className="lg:max-w-lg lg:w-w-1/3 md:w-1/2 w-5/6 mb-10 md:mb-0">
        {/* <img
          className="object-cover object-center rounded"
          alt="hero"
          src={Employee1}
        /> */}
      </div>
    </div>
    {/* IT Operations Management */}
    {/* Hardware & Integration */}
    <div className="lg:h-[40rem]  h-[70rem] bg-[#2392D0] flex justify-center items-center text-white">
      <div
        className="h-full lg:px-0 bg-left-bottom bg-no-repeat lg:pt-0 pt-10 px-5 w-full flex lg:items-center items-start  lg:justify-center"
        // style={{
        //   backgroundImage: `url(${Employee2})`,
        // }}
      >
        <div className="flex ">
          <div className="max-w-[1360px] flex flex-col w-full items-end justify-end">
            <div className="md:w-3/4 w-full lg:pl-24 md:pl-16 flex flex-col justify-start md:text-left items-start text-center text-white">
              <h1 className="title-font sm:text-6xl text-7xl mb-4 font-normal  text-white">
                Hardware & Integration
              </h1>
              <div className="flex mb-4"></div>
              <p className="leading-relaxed text-white mb-8 text-lg ">
                Provide integration services of various systems that exist
                in the company / organization to ensure that these systems
                can interact and communicate well to support that
                activities of your company / orgc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Hardware & Integration */}
    {/* Back */}
    <div className="py-20 w-full flex justify-center items-center">
      <button
        onClick={() => {
          setPage(0);
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Add smooth scrolling behavior
          });
        }}
        className="flex px-5 py-3 gap-x-2 bg-[#2173AA] text-white rounded-xl font-bold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
        Back
      </button>
    </div>
    {/* Back */}
  </>
)}
    </>
  );
}

