import React from "react";
import Building from "../../Assets/Image/building.png";
import perisai from "../../Assets/Logo/Peris.ai.png";
import Construction from "../../Assets/Image/underconstruction.png"


export default function Product() {
  return (
    <>
      <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
    <img className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src={Construction}/>
    <div className="text-center lg:w-2/3 w-full">
      <h1 className="title-font sm:text-4xl text-3xl mb-4 font-bold  ">WEB IS UNDER <br />CONSTRUCTION</h1>
    </div>
  </div> 
    </>
  );
}
// {/* Banner */}
// <div
// className="xl:py-48 lg:py-48 md:py-32 py-32 flex flex-col justify-center  w-full h-[70vh] bg-cover bg-center text-white xl:px-20 lg:px-20 md:px-16 sm:px-14 px-12 capitalize shadow-xl "
// style={{ backgroundImage: `url(${Building})` }}
// >
// <div className="xl:w-1/2 lg:w-1/2 md:w-3/4">
//   <h1 className="xl:text-4xl lg:text-4xl md:text-2xl text-3xl font-semibold">
//     Our Featured Products
//   </h1>
// </div>
// </div>
// {/* Banner */}
// <div class="container px-5 py-24 mx-auto flex flex-col">
// <div class="lg:w-4/6 mx-auto">
//   <div class="rounded-tl-[50px] rounded-br-[50px] h-64 overflow-hidden">
//     <div class="h-full bg-[#27374D]  px-8 pt-8 pb-12 rounded-tl-[50px] rounded-br-[50px] overflow-hidden text-center relative">
//       <img
//         src={perisai}
//         alt="perisai"
//         className="object-fill item-center h-full w-3/4  "
//       />
//     </div>
//   </div>
//   <div class="flex flex-col sm:flex-row mt-10">
//     <div class="sm:w-1/3 text-center sm:pr-8 sm:py-8">
//       <div class="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
//         <svg
//           fill="none"
//           stroke="currentColor"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//           stroke-width="2"
//           class="w-10 h-10"
//           viewBox="0 0 24 24"
//         >
//           <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
//           <circle cx="12" cy="7" r="4"></circle>
//         </svg>
//       </div>
//       <div class="flex flex-col items-center text-center justify-center">
//         <h2 class="font-medium title-font mt-4 text-gray-900 text-lg">
//          Most Fitured Product
//         </h2>
//         <div class="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
//         <p class="text-base">
//         Protect Your Valuable Organization's IT Assets & Infrastructure NOW
//         </p>
//       </div>
//     </div>
//     <div class="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
//       <p class="leading-relaxed text-lg mb-4">
//         At Peris.ai, we understand that organizations’ digital security
//         challenges are ever-evolving. we have intergrated innovative
//         machine learning algorithms into our products to stay one step
//         ahead, providing real-time, proactive protection against known
//         anda emerging threats. 
//         <br/>
//          Peris.ai is a trailblazer in the
//         cybersecurity industry, relentlessly dedicated to safeguarding
//         businesses in today’s complex digital landscape. 
//         <br /> 
//         Founded
//         by team of seasoned cybersecurity veterans, our mission is to
//         empower businesses by providing them with advanced, AI-driven
//         cybersecurity solutions that are both accessible and efficient.
//       </p>
//       <a href="1" class="text-indigo-500 inline-flex items-center">
//         Learn More
//         <svg
//           fill="none"
//           stroke="currentColor"
//           stroke-linecap="round"
//           stroke-linejoin="round"
//           stroke-width="2"
//           class="w-4 h-4 ml-2"
//           viewBox="0 0 24 24"
//         >
//           <path d="M5 12h14M12 5l7 7-7 7"></path>
//         </svg>
//       </a>
//     </div>
//   </div>
// </div>
// </div>