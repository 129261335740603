import React from "react";
import Logo from "../../Assets/Logo/logowhite.svg";
import { Link } from "react-router-dom";
import { BurgerClose as Burger } from 'react-burger-icons';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <>
      {/* dekstop */}

      <div className="text-gray-600 body-font  bg-[#071B34] flex z-50 justify-center items-center">
        <div className="w-full md:px-18 px-10 justify-between z-50 items-center  flex p-5 ">
          <div
            href="Logo"
            className=" w-36 flex title-font font-medium justify-center items-center text-white mb-2 md:mb-0"
          >
            <img src={Logo} alt="Logo" />
          </div>
          <nav className="md:ml-auto md:mr-auto md:flex hidden flex-wrap items-center text-base justify-center text-white text-semibold">
            <Link to={"/"}>
              <p className="mr-10 text-white font-semibold hover:bg-white rounded-full p-0.5 hover:text-black  ">
                Home
              </p>
            </Link>
            <Link to={"/about"}>
              <p className="mr-10 text-white font-semibold hover:bg-white rounded-full p-0.5 hover:text-black">
                About Us
              </p>
            </Link>
            <Link to={"/services"}>
              <p className="mr-10 text-white font-semibold hover:bg-white rounded-full p-0.5 hover:text-black">
                Service
              </p>
            </Link>
            <Link to={"/products"}>
              <p className="mr-10 text-white font-semibold hover:bg-white rounded-full p-0.5 hover:text-black">
                Product
              </p>
            </Link>
            <Link to={"/advantages"}>
              <p className="mr-10 text-white font-semibold hover:bg-white rounded-full p-0.5 hover:text-black">
                Advantage
              </p>
            </Link>
          </nav>
          <div className="flex lg:hidden items-center justify-center gap-x-5">
           
            <button
              onClick={() => setNavbarOpen(!navbarOpen)}
              className="xl:hidden lg:hidden h-8 w-8"
            >
              {/* {navbarOpen ? (
                <CloseCircle
                  className="h-8 w-8 xl:hidden lg:hidden transition-all"
                  color="#ffffff"
                />
              ) : (
                <HambergerMenu
                  className="h-8 w-8 xl:hidden lg:hidden transition-all"
                  color="#ffffff"
                />
              )} */}
              <Burger isClosed={navbarOpen} />
            </button>
          </div>
        </div>
      </div>
      {/* dekstop */}
      {/* mobile */}
      <div
        className={`${
          navbarOpen ? "  translate-y-[78px]" : "-translate-y-[1000px] "
        } top-0 absolute flex-col md:hidden flex z-10 bg-[#071B34] w-full transition-[0.3s]
             px-10 py-10 pb-10 overflow-y-auto rounded-b-2xl  `}
      >
        <ul className="flex flex-col gap-y-5 font-bold text-white">
          <Link to={"/"}>
            <li className="cursor-pointer">Home</li>
          </Link>
          <Link to={"/about"}>
            <li className="cursor-pointer">About Us</li>
          </Link>
          <Link to={"/services"}>
            <li className="cursor-pointer">Service</li>
          </Link>
          <Link to={"/products"}>
            <li className="cursor-pointer">Product</li>
          </Link>
          <Link to={"/advantages"}>
            <li className="cursor-pointer">Advantage</li>
          </Link>
        </ul>
      </div>
      {/* mobile */}
    </>
  );
}
